<template>
  <div class="add-project">
    <div class="icon">
      <Icon type="md-add" />
    </div>
    <div class="right">
      <div class="title">新建任务</div>
      <div class="desc">选择一块区域来新建您的项目</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'addAirLineProject',
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.add-project {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  background-color: #fff;
  padding: $space20;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 16px;
  cursor: pointer;
  .icon {
    flex: 0 0 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #409eff;
    font-size: 40px;
    color: #fff;
    border-radius: 50%;
    .ivu-icon {
      margin-top: 1px;
    }
  }
  .right {
    margin-left: $space10;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }
    .desc {
      white-space: nowrap;
    }
  }
}
</style>
