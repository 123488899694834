<template>
  <div ref="abs"
       class="more-action">
    <div class="item"
         v-for="(action, index) in config"
         :key="index"
         @click="action.cb(action)">{{action.text}}</div>
  </div>
</template>

<script>
export default {
  name: 'moreAction',
  props: {
    config: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  mounted() {
    let parent = this.$refs.abs.parentNode;
    this.$refs.abs.style.left = parent.offsetLeft + 10 + 'px';
    this.$refs.abs.style.top = parent.offsetTop + 'px';
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.more-action {
  z-index: -1;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  padding: 5px 5px 0;
  width: 80px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  .item {
    padding: 0 5px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #707070;
    white-space: nowrap;
    border-bottom: 1px solid rgba(112, 112, 112, 1);

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
