<template>
  <div class="search-wrapper">
    <Input prefix="ios-search"
           @on-enter="enter"
           placeholder="搜索您的项目"
           v-model="searchKeyWord" />
  </div>
</template>

<script>
export default {
  name: 'serachInput',
  data() {
    return {
      searchKeyWord: '',
    };
  },
  mounted() {},
  methods: {
    enter() {
      this.$emit('search', this.searchKeyWord);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  /deep/ .ivu-input {
    text-align: center !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    box-shadow: none;
    background-color: #fafafa;
    border-radius: 0;
    font-size: 18px;
    color: #999;
  }
}
</style>
