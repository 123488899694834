<template>
  <div class="air-line">
    <div class="left">
      <div class="title">{{info.projectName}}</div>
      <div class="info">
        <Icon type="ios-time-outline" />
        <span>{{info.date}}</span>
      </div>
      <div class="info">
        <Icon type="ios-pin-outline" />
        <span>{{info.location}}</span>
      </div>
    </div>
    <div class="right">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <more-actions :config="info.actions" />
    </div>
  </div>
</template>

<script>
import MoreActions from '@/components/airLine/commonComponents/more-actions.vue';
export default {
  name: 'airLineProject',
  components: { MoreActions },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.air-line {
  width: 100%;
  height: 120px;
  border-radius: 10px;
  background-color: #fff;
  padding: $space20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .left {
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      margin-bottom: 10px;
    }
    .info {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      max-width: 240px;
      i {
        margin-right: 10px;
      }
      /deep/ .ivu-icon-ios-pin-outline:before {
        font-size: 20px;
      }
      /deep/ .ivu-icon-ios-time-outline:before {
        font-size: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .circle {
      width: 5px;
      height: 5px;
      background: #e1e1e1;
      border-radius: 50%;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:hover {
      /deep/ .more-action {
        opacity: 1;
        z-index: $tabZIndex;
      }
    }
  }
}
</style>
